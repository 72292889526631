<template>
  <div id="app" >
    <router-view></router-view>
  </div>
</template>

<script>
// import Login from './components/LoginComponent.vue';

// export default {
//   name: 'App',
//   components: {
//     Login,
//   },
// };
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Almarai');

html, body {
  font-family: 'Almarai', sans-serif;
}

#app {
  font-family: 'Almarai', sans-serif;
}
</style>
