<template>
<div  class="container"  >
<!-- v-if="entry" dir="rtl" -->
	<!-- <div class="row">
		<header>
			<div class="wrapper">
				<div class="logo">
					<a href="#"> <img src="https://lia.ly/wp-content/uploads/2021/01/LOGO-img-ar.svg" alt="Libyan Investment Corporation" height="80"></a>
				</div>
				<nav>
					<a href="#"></a> <a href="#"></a> <a href="#"></a> <a href="#"></a> <a href="#">تواصل معنا</a>
				</nav>
			</div>
		</header>
		<div class="banner-area text-center">
			<h2> تأهيل و تطوير القدرات الشبابية الليبية</h2>
		</div>
		<div class="content-area">
		
		</div>
	</div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header text-white" style="background-color:#abb8c3;">
              :passive-color="passiveColor"  
              <h3 class="text-center">تفاصيل الطلب</h3>
              <vue-step-progress :steps="steps" :currentStep="currentStep" :passive-thickness="1" :active-thickness="1" :line-thickness="1" :active-color="iconP"></vue-step-progress>
            </div>
            <div class="card-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="row">Entry ID</th>
                    <td>{{ entry.entry_id }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Entry Key</th>
                    <td>{{ entry.entry_key }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Entry Date</th>
                    <td>{{ entry.entry_date }}</td>
                  </tr>
                  <tr v-for="item in entry.items" :key="item.field_id">
                    <th scope="row">{{ item.field_name }}</th>
                    <td>
                      {{ item.field_value }}
                      <a :href="item.file_path" target="_blank" v-if="item.file_path">
                        <img src="https://cdn-icons-png.flaticon.com/512/337/337946.png" alt="Libyan Investment Corporation" height="80">
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
    </div> 

  </template>
  
  <script>
  export default {
    name: 'DashboardComponent',
    props: {
      entry: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        steps: [], // define your steps array here
        currentStep: 0 // define your currentStep here
      };
    }
  };
  </script>
  
  <style scoped>
   body {
            font-family: 'Cairo', sans-serif;
            direction: rtl;
            background-color: #f5f5f5;
            margin: 0;
            padding: 0;
            text-align: right;
        }
        .header {
            background-color: #214439;
            color: white;
            padding: 20px;
            text-align: center;
        }
        .header img {
            width: 80px;
            margin-bottom: 10px;
        }
        .containerDashboard {
            background-color: white;
            max-width: 800px;
            margin: -50px auto 0;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            text-align: center;
            position: relative;
            top: 20px;
        }
        .title {
            font-size: 24px;
            margin-bottom: 10px;
        }
        .subtitle {
            font-size: 18px;
            margin-bottom: 20px;
            color: gray;
        }
        .steps {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;
        }
        .step {
            text-align: center;
        }
        .step .circle {
            width: 50px;
            height: 50px;
            background-color: #21412a;
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 10px;
        }
        .step .circle.completed {
            background-color: #d4af37;
        }
        .step p {
            margin: 0;
        }
        .line {
            flex-grow: 1;
            height: 2px;
            background-color: #21412a;
            position: relative;
            top: -25px;
        }
  </style>